<template>
  <div>
    <!-- input Recherche -->
    <b-row>
      <!-- <b-col v-if="user.role === 'admin'">
        <b-form-group
          label="Depot"
          label-for="Depot"
        >
          <v-select
            v-model="destinationRepository"
            :clearable="false"
            placeholder="Dépots destination"
            label="name"
            :options="repositories"
          />
        </b-form-group>
      </b-col> -->
      <b-col>
        <div class="custom-search d-flex justify-content-end">

          <b-form-group>
            <b-button
              v-if="user.role === 'admin'"
              :to="{ name: 'bon-retour-inter-agence-add' }"
              variant="gradient-primary"
              class="ml-1 d-inline-block"
            >
              <feather-icon icon="PlusIcon" />
              <span class="ml-1">Ajouter inter retour</span>
            </b-button>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="tableColumns"
      :rows="returnVouchers"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-info"
              :to="{ name: 'bon-retour-inter-agence-show',
                     params: { id: props.row.id} }"
            >  <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
          </span>
          <span>
            <router-link
              v-if="!props.row.is_validated && props.row.destination_repository.id === user.repository"
              class="text-success"
              :to="{ name: 'bon-retour-inter-agence-validate',
                     params: { id: props.row.id} ,}"
            >
              <feather-icon
                :id="`invoice-row-${props.row.id}-show-icon`"
                icon="CheckSquareIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="
                  $router.push({
                    name: 'bon-retour-inter-agence-validate',
                    params: { id: props.row.id} ,
                  })
                "
              />
            </router-link>
            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ count }} entrées
            </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormSelect, BButton,
  BSpinner, BTooltip, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'
import storeAuth from '@/store/store'

export default {
  name: 'ListInterAgency',
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    // vSelect,
    BCol,
    BRow,
  },
  data() {
    return {
      size: 40,
      isLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Reference',
          field: 'id',
        },
        {
          label: 'Nombre des colis',
          field: 'orders_count',
        },
        {
          label: 'Total Prix TTC (TND)',
          field: 'total_price',
          formatFn: value => `${value.toFixed(3)} TND`,
        },
        {
          label: 'Depot Source',
          field: 'source_repository.name',
        },
        {
          label: 'Depot Destination',
          field: 'destination_repository.name',
        },
        {
          label: 'date | heure',
          field: 'created_at',
          formatFn: this.formatFn,
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      returnVouchers: [],
      searchTerm: '',
      currentPage: 1,
      count: 0,
      user: {},
      repositories: [],
      destinationRepository: '',
    }
  },
  computed: {
    tableColumns() {
      if (this.user.role === 'admin') {
        return this.columns
      }
      return this.columns.filter(column => column.field !== 'source_repository')
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getReturnVouchers(1)
    if (this.user.role === 'admin') {
      // console.log('admin')
    }
    this.getRepositories()
  },
  methods: {
    async pageNext(currentPage) {
      if (this.supplier !== '') {
        // test
      } else {
        this.getReturnVouchers(currentPage)
      }
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getReturnVouchers(currentPage) {
      this.isLoading = true
      const { data } = await axios.get('api/return-vouchers/inter-agency-return', { params: { p: currentPage } })
      this.returnVouchers = data.results
      this.count = data.count
      this.isLoading = false
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
